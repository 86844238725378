<script lang="ts">
	import arrowRight from "../../../../core/assets/icons/arrowRight.svg?raw";
	import Icon from "../Icon.svelte";
	import { lazyLoadVideo } from "./lazyLoadVideo.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";
	import { getTranslate } from "../../utils/getTranslate.js";
	import translations from "./Video.translations.json";

	export let video: string;
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let noLoop = false;
	export let autoplay = true;
	export let controls = false;
	export let muted = false;
	export let playsinline = true;
	export let videoClass = "";
	export let icon = false;
	export let poster: string | undefined = undefined;
	export let lazy = false;
	export let disabled = false;
	export let sourcesets: Partial<Record<SourceSetQuery, string>> = {};

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, string][];

	export let element: HTMLVideoElement | null = null;
	let hideIcon = false;

	const translate = getTranslate(translations);
</script>

<svelte:head>
	{#if !lazy && poster}
		<link rel="preload" as="image" href={poster} fetchpriority="high" type="image/webp" />
	{/if}
</svelte:head>

<video
	bind:this={element}
	use:lazyLoadVideo={{ enabled: lazy, autoplay }}
	autoplay={autoplay && !lazy}
	{height}
	{width}
	loop={!noLoop}
	{muted}
	controls={controls && (hideIcon || !icon) && !disabled}
	{playsinline}
	class="w-full {videoClass}"
	{poster}
	preload={lazy ? "none" : undefined}
>
	{#each sortedSourcesets as [query, video]}
		<source media={parseSourceSet(query)} src="{video}#t=0.001" />
	{/each}

	<source src="{video}#t=0.001" />
</video>
{#if icon && !hideIcon && controls}
	<button
		aria-label={translate("play")}
		class="absolute inset-0 flex items-center justify-center transition-opacity duration-500 hover:opacity-50"
		on:click={async () => {
			await element?.play();
			hideIcon = true;
		}}
		{disabled}
	>
		<Icon icon={arrowRight} class="h-16 w-16 text-white md:h-32 md:w-32" />
	</button>
{/if}
